import "./App.css";
import Main from "./Component/Main";
import About from "./Component/About";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import Delivery from "./Component/Delivery";
import Privacy from "./Component/Privacy";
import ProductDetail from "./Component/ProductDetail";
import CartPage from "./Component/CartPage";
import CheckOut from "./Component/CheckOut";
import { CartProvider } from "./Component/CartContext";
import PaySuccess from "./Component/SuccessPay";
import Terms from "./Component/Terms";
import Particular from "./Component/Particular";

function App() {
  return (
    <CartProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/shopDetails/:id" element={<ProductDetail />} />
          <Route path="/cartpage" element={<CartPage />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/paysuccess" element={<PaySuccess />} />
          <Route path="/particular" element={<Particular />} />
        </Routes>
      </HashRouter>
    </CartProvider>
  );
}

export default App;
