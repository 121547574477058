import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

import { Link } from "react-router-dom";

function Particular() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>特定商取引法に基づく表記</h1>
          </div>
          <div className="particular-table">
            <table border="0" cellpadding="0" cellspacing="0">
              <tbody>
                <tr>
                  <th>販売業者名</th>
                  <td>田岡芳範</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>〒7612207 香川県綾歌郡綾川町羽A-1</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>
                    フリーダイヤル：81-080 55 485458 connect@uke-cloth.xyz
                  </td>
                </tr>
                <tr>
                  <th>電話応対時間</th>
                  <td>
                    年中無休　午前10時～午後6時00分まで（年末・年始を除く）
                    <br />
                    商品の詳細やご質問をお気軽にお問い合わせください。
                  </td>
                </tr>
                <tr>
                  <th>代表者</th>
                  <td>代表取締役会長兼社長 似鳥昭雄</td>
                </tr>
                <tr>
                  <th>お問い合わせ</th>
                  <td>
                    <Link to="/">お問い合わせフォーム</Link>
                  </td>
                </tr>
                <tr>
                  <th>ウェブサイトURL</th>
                  <td>https://www.uke-cloth.xyz/</td>
                </tr>
                <tr>
                  <th>申込みの有効期限</th>
                  <td>このウェブサイトが表示されている限り</td>
                </tr>
                <tr>
                  <th>お支払い方法</th>
                  <td>
                    クレジットカード /　銀行振込 /　商品代引(現金）
                    /　コンビニ(後払い）
                    <br />
                    ※メーカー直送の商品は、商品代引をご利用いただけません。
                    <br />
                    ※発行形態(海外発行等)によりご利用になれない場合がございますので、予めご了承ください。
                  </td>
                </tr>
                <tr>
                  <th>商品代金以外の 必要料金</th>
                  <td>
                    ・消費税
                    <br />
                    ・送料　詳細につきましては「配送について」のページをご覧下さい。
                    <br />
                    ・銀行振込手数料
                    <br />
                    ・コンビニ（後払い）手数料
                  </td>
                </tr>
                <tr>
                  <th>お支払い金額に ついて</th>
                  <td>
                    詳細につきましては「お支払いについて」のページをご覧下さい。
                    <br />
                    お支払い金額につきましては以下の通りとなります。
                    <br />
                    <p className="par-td-strong">
                      お支払い金額 =
                      <span className="par-td-p">
                         表示金額（税込）＋ 送料（税込）
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>引き渡し期間</th>
                  <td>
                    お客様のご入金を確認後、一部商品を除きご注文確定から5日から10日前後となります。
                    <br />
                    それ以上遅れる場合はメールにて事前にご報告いたします。
                    <br />
                    （メール以外での連絡をご希望の場合は、注文時にカートの「ご質問・ご要望」
                    <br />
                    欄に記入していただくか、当店までお問い合わせください）
                  </td>
                </tr>
                <tr>
                  <th>開梱・組立・設置</th>
                  <td>
                    開梱・組立・設置：
                    一部例外を除き、開梱・組立て・設置サービスは行っておりません。
                    あらかじめ、ご了承下さいませ。
                    <br />
                    詳細につきましては「よくある質問とその答え：商品の配送・組立」のページをご覧下さい。
                  </td>
                </tr>
                <tr>
                  <th>廃棄処分</th>
                  <td>
                    一部例外を除き、廃棄処分サービスは行っておりません。予め、ご了承下さいませ。
                    詳細につきましては、
                    <Link to="/">完成家具引き取りサービスをご覧ください。</Link>
                  </td>
                </tr>
                <tr>
                  <th>返品について</th>
                  <td>
                    お買い上げ商品の品質については万全の注意を払っておりますが、初期不良及び、配送中の
                    <br />
                    事故等で　破損・キズが生じた場合には、 商品お届け後
                    14日以内にご連絡下さい。送料弊社負担で交換もしくは返品・返金をさせていただきます。
                    <br />
                    ・一度ご使用になった商品、お客様の責任でキズ、汚れが生じた商品の返品・交換は一切お受けできませんので予めご容赦願います。
                    <br />
                    ・オーダー商品の返品・交換はお受けできません。
                    <br />
                    ・組み立て途中あるいは組み立て後の商品の返品・交換はお受けできません。
                    <br />
                    ・アウトレット商品は新品商品と交換できません。アウトレット記載内容以外の初期不良が発生した場合、修理もしくは返品・返金をさせていただきます。
                  </td>
                </tr>
                <tr>
                  <th>返品送料</th>
                  <td>
                    「<Link to="/">お支払いについて</Link>
                    」のページをご覧下さい。
                  </td>
                </tr>
                <tr>
                  <th>個人情報について</th>
                  <td>
                    詳細につきましては「<Link to="/">個人情報保護方針</Link>
                    」のページをご覧下さい。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Particular;
