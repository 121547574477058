import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faGear,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

function Terms() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>Terms of service</h1>
          </div>

          <p>
            Our details
            <br />
            E-cloth Limited (we, our and us) operates the website.
            <br />
            E-cloth Limited is a limited liability company incorporated in
            England and Wales (company number 03111707). Our registered address
            is 3 Boyne Park, Tunbridge Wells, Kent, TN4 8EN. Our VAT
            registration number is GB661819615.
            <br />
            Our contact telephone number is 01892 893131and our contact email
            address is connect@uke-cloth.xyz
          </p>
          <p>
            Your responsibility for others who access our website using your
            device or internet connection
            <br />
            You must ensure that any persons who access our website on your
            computer(s) or device(s), or who are permitted or able to access our
            website on your computer(s) or device(s), or who use your internet
            connection, are aware of these Terms of Use and all other
            documentation referred to in them and that such persons also agree
            to be bound by and to comply with these Terms of Use. If for any
            reason whatsoever, such persons do not agree to these Terms of Use
            or do not wish to be bound by them, they must not access or use our
            website, and you must not permit them to do so.
          </p>
          <p>&nbsp;</p>
          <p>
            Other documents governing your use of our website
            <br />
            In addition to these Terms of Use, your use of our website is also
            governed by the following documents:
            <br />
            Our privacy policy, which is available at
            https://www.e-cloth.com/pages/privacy-policy. Our privacy policy
            governs our use of your information. It sets out the types of
            information we collect, the reasons we collect it, how we use it,
            where we may pass it on to any third parties, in what circumstances
            and for what reasons, and any other relevant information relating to
            our use and/or processing of your information and your rights in
            relation to your information.
            <br />
            Our cookies policy, which is available at
            https://www.e-cloth.com/pages/cookie-policy. Our cookies policy
            governs our use of cookies and similar technologies on our website.
            It sets out the types of cookies we use, the purposes for which we
            use them, the circumstances in which we may place cookies on your
            computer, device or browser, and other relevant information relating
            to cookies, such as how to change your browser preferences and
            settings to accept or reject cookies.
            <br />
            Our terms of sale, which are available at
            https://www.e-cloth.com/pages/delivery-charges-information. Our
            terms of sale govern any purchases or orders you make for goods or
            services on our website. They set out the status of any orders
            placed, the contract terms relating to delivery and performance of
            those orders, any exclusions that apply to you and other relevant
            terms relating to our supply of goods, services or digital content.
            <br />
            By accessing and using our website, you agree to be bound by the
            terms and conditions contained in these Terms of Use, you
            acknowledge that we will process your information in accordance with
            our privacy policy, and our use of cookies and similar technologies
            in accordance with our cookies policy.
            <br />
            If you do not agree to the terms set out in these Terms of Use, you
            must not use our website.
          </p>
          <p>
            Availability of our website
            <br />
            We make no representations and provide no warranties that:
            <br />
            the website will be made available at any specific time or from any
            specific geographical location;
            <br />
            your access to the website will be continuous or uninterrupted; or
            <br />
            the website will be accessible or optimised on all browsers,
            computers, tablets, phones or viewing platforms.
            <br />
            We reserve the right to suspend access to all or part of the website
            for any reason, including for business or operational reasons, such
            as improving the appearance or functionality of the website, content
            updates, periodic maintenance, or to resolve any issues that we
            become aware of. Wherever we anticipate that we need to suspend
            access to the website for a considerable period of time, we will try
            to provide you with prior notice where reasonably practicable.
            <br />
            Our website is provided for users in the United Kingdom only.
            Although it may be possible to access the website from other
            countries, we make no representation that our website is compliant
            with any legal requirements in force in any jurisdiction other than
            the United Kingdom, or that the content available on the website
            will be appropriate for users in other countries or states.
          </p>
          <p>
            Changes we may make to these Terms of Use and other documentation
            <br />
            We reserve the right to update these Terms of Use, our privacy
            policy, our cookies policy and any other documentation referred to
            in any of these documents from time to time. We may change our Terms
            of Use and other documentation for any reason, including:
            <br />
            to reflect any changes in the way we carry out our business;
            <br />
            to account for any changes we make to our website, including,
            without limitation, any new features or functionality we provide,
            any adjustments to the means by which we provide notices to you, or
            any changes in the content, purpose or availability of the website;
            <br />
            to accurately describe our current data-processing activities so
            that you are kept up to date with our latest practices;
            <br />
            to inform you of any changes in the way that we use cookies or
            similar information-gathering technologies; or
            <br />
            to ensure that our documentation complies and remains compliant with
            any and all current and future applicable laws, regulations and
            official guidance.
            <br />
            If required by law, we will provide you with notice of any changes
            in these Terms of Use or the other documentation referred to in them
            by posting a notice on the website and/or by posting an updated
            version of these Terms of Use or other such documentation on our
            website with a new effective date stated at the beginning of them.
            <br />
            By continuing to access our website after we have updated our Terms
            of Use, terms of sale, and/or user content agreement, you agree to
            be bound by those updated versions.You also acknowledge that by
            continuing to access our website after we have updated our privacy
            policy and/or our cookies policy, that the practices set out in
            those updated policies will apply to our handling of your
            information and our use of cookies and similar technologies.
            <br />
            You must check these Terms of Use and all other documentation
            referred to in them each time you access our website in order to
            ensure that you are aware of the terms that apply to you at that
            time.
            <br />
            The date that these Terms of Use and/or any other documents
            (including our privacy policy and cookies policy) were last amended
            is set out at the top of that document and is referred to as that
            document’s “effective date”.
          </p>
          <p>
            Your account details
            <br />
            If we provide you with account information such as a user name,
            identification number, account code and/or password, you must keep
            such information confidential and secret and not disclose it to
            anyone. All account information is provided for use of the named
            account holder only, and not for any other person. You are
            responsible for any consequences of unauthorised access to your
            account due to any disclosure of your account information to any
            third party.
            <br />
            Where we provide you with the option to select your own login
            information, including a password, we recommend that you supply
            login information unique to your own use of this website, and do not
            use information from other accounts you may hold with other websites
            or any easily discoverable information about you. You are
            responsible for any consequences of unauthorised access to your
            account due to any disclosure of your login information to any third
            party.
            <br />
            You must never use another user’s account without permission. When
            creating your account, you must provide accurate and complete
            information. You agree that you will not solicit, collect or use the
            login credentials of other individuals. We prohibit the creation of,
            and you agree that you will not create, an account for anyone other
            than yourself. You also represent that all information you provide
            to us upon registration and at all other times will be true,
            accurate, current, and complete. You agree to update your
            information as necessary to maintain its truth and accuracy.
            <br />
            We reserve the right to withdraw access to your account without
            notice for any actual or suspected breach of these Terms of Use or
            any other documentation referred to in them, including, without
            limitation, where we suspect that there has been unauthorised access
            to your account, or any unauthorised disclosure of your login
            information.
            <br />
            If you know or suspect that the confidentiality of your login
            information has been compromised, for example, by the disclosure of
            such information to any third party, you must immediately change
            your password. If you are unable to change your password, you must
            immediately notify us by email, at connect@uke-cloth.xyz
          </p>
          <p>
            Ownership of material on our website All trade marks, service marks,
            trade names, logos, copyright and other intellectual property rights
            in our website and its content are either owned by us or licensed to
            us. All such rights are protected by intellectual property laws
            around the world, and all rights are reserved. Any use of the
            website and its contents, other than as specifically authorised
            herein, is strictly prohibited. Any rights not expressly granted
            herein are reserved by us. ‘e-cloth’ is a registered trade mark
            ofE-cloth Limited. The trade marks, service marks, trade names,
            logos and other branding owned by third parties and used or
            displayed on or via our website (collectively, “Third Party
            Mark(s)”) may be trade marks of their respective owners, who may or
            may not endorse or be affiliated with or connected with us. Except
            as expressly provided in these Terms of Use, or in terms provided by
            the owner of a Third Party Mark, nothing in these Terms of Use or on
            or via the website should be construed as granting, by implication,
            estoppel, or otherwise, any licence or right to use any of our or
            any Third Party Marks that are used or displayed on the website,
            without the respective owner’s prior written permission, in each
            instance. All goodwill generated from the use of our trade marks
            will benefit us exclusively.
          </p>
          <p>Sell on Approved Websites Only</p>
          <p>
            Selling on 3<sup>rd</sup> party sites (Amazon, Walmart, eBay, etc.),
            drop-ship accounts (Wayfair, Home Depot, Lowes, Faire.com, etc.), or
            classified sites (Craigslist, Facebook Marketplace, etc.) is
            strictly prohibited.
          </p>
          <p>
            Information and content on our website provided on non-reliance
            basis Our website is made available to you in order to provide you
            with general information about us, our business, and any products or
            services that we offer from time to time. We do not make our website
            available for any other purposes, except as expressly provided in
            these Terms of Use. The content on our website is not intended to be
            construed as advice. You must not rely on any of the content of our
            website for any purposes whatsoever, and you must seek your own
            independent professional advice before deciding to take any course
            of action on the basis, whether in whole or in part, of any of the
            content available on our website at any time. We make no
            representations and provide no warranties whatsoever, whether
            express or implied, that any of the content or materials available
            on our website from time to time are accurate, up to date or
            complete.
          </p>
          <p>
            Permitted use of materials on our website The content on our website
            is provided for your personal, private and non-commercial use only.
            You may print or share the content from our website for lawful
            personal, private and non-commercial purposes, and you may also make
            others within your organisation aware of the content on our website.
            You may not otherwise extract, reproduce or distribute the content
            of our website without our prior written consent. Whenever you
            print, download, share or pass on content from our website to
            others, you must not make any additions or deletions or otherwise
            modify any text from our website, you must not alter or change any
            images, media or graphics from our website in any way, you may not
            remove any accompanying text from such images, media or graphics,
            and you must ensure that all content passed on to any third party is
            an accurate representation of the content as it appears on our
            website. You are prohibited from using any robots, spiders, data
            mining or scraping technology or any similar third party tools for
            the extraction or reproduction of any data or content from our
            website without our prior written consent. Whenever you pass on any
            content or materials from our website to anyone, you must
            acknowledge us as the authors of such content or materials (or any
            other authors wherever credited by us) at the time when you pass on
            such content or materials.
          </p>
          <p>
            Prohibited uses of our website You must not reproduce, duplicate,
            copy or resell any part of our website or any content from our
            website, save and except to the extent expressly permitted in these
            Terms of Use. You must not, without our prior written consent,
            access, interfere with, damage or disrupt in any way our website or
            any part of it, our systems, any of our hardware or equipment or any
            networks on which our website is hosted, any software that we use to
            create or modify the website or to make the website available to
            you, or any hardware, equipment, network, server, software or
            technology owned or operated by us or any third party. You must use
            our website for lawful purposes only and in accordance with these
            Terms of Use. You must not use our website: for any purpose that is
            unlawful or that in any way breaches any applicable laws or
            regulations, whether local, national or international; for any
            fraudulent purposes whatsoever; to conduct any unsolicited or
            unauthorised advertising or direct or indirect marketing to anyone
            by any means, or to otherwise spam, communicate with or market to
            anyone any goods, services or business not authorised by us; to
            upload, host or transmit any viruses, malware, adware, spyware,
            worms, Trojan horses, keystroke loggers, spyware, logic bombs, time
            bombs or any other harmful programs or code which could adversely
            affect the use or operation of the website, our hardware or systems,
            or the computers, tablets, phones or other devices of any users or
            other third parties, or to upload any content or materials
            containing any such content; to communicate with, harm or attempt to
            harm children in any way; or in any way or for any purpose that
            breaches these Terms of Use or the terms of any of the documents
            these Terms of Use refer to. You must not submit any information
            about you to us if you are under the age of 18, or about any other
            person who is either: under the age of 18; or if they are aged 18 or
            above, where you have not received their prior written consent to
            submit information about them to us. You must not submit to us any
            information which is considered ‘sensitive personal information’.
            ‘Sensitive personal information’ is information about you or any
            other person which reveals your or their racial or ethnic origin,
            political opinions, religious or philosophical beliefs, trade union
            membership or which is genetic data, biometric data, information
            which concerns your or their health, sex life or sexual orientation.
            If you accidentally or intentionally submit such information to us,
            you will be considered to have consented to our processing of that
            information on the basis of Article 9(2)(a) of the General Data
            Protection Regulation (Regulation (EU) 2016/769).
          </p>
          <p>
            Viruses and other harmful content We do not guarantee that our
            website does not contain viruses or other malicious software. We
            shall not be responsible for any bugs or viruses on our website, or
            any software that might be transferred to your computer from our
            website, or any consequences which the presence or operation of such
            programs may have. You must ensure that you have in place up-to-date
            and effective anti-virus protection on your computer or other
            browsing device. You must not upload or otherwise introduce to our
            website any viruses, malware, spyware, adware, Trojan horses, worms,
            logic bombs, time bombs, keystroke loggers or any other programs or
            code that is harmful or malicious. You must not use any third
            parties, software or technology to attempt to gain unauthorised
            access to our website, our servers, systems, hardware, software or
            data. You must not attempt to perform any denial of service type
            attack on our website. You must not perform any action which would
            contravene the Computer Misuse Act 1990. We may report any breach or
            suspected breach of this clause 11(Viruses and other harmful
            content)to the relevant authorities and may disclose your identity.
          </p>
          <p>
            Links to other websites Links to third party content or websites may
            appear on our website from time to time. We are not responsible for
            the content of any websites accessible via any link(s) on our
            website. All content on third party websites is outside of our
            control, and we do not represent or warrant that such content is
            related to us or our website, suitable or appropriate for use or
            viewing, lawful or accurate. Any third party website accessible via
            a link on our website may collect and process your information. We
            are not responsible for any data-processing activities carried out
            by any third party website which is linked to from our website, and
            we disclaim any and all liability in respect of the same. You should
            check the privacy policy of any such third party to establish how
            they may use your information before you decide to use their website
            and its features.
          </p>
          <p>
            Links to our website You may not link to our website without our
            prior written consent. Where you have obtained our consent to link
            to our website: you may provide links to our website on other
            websites owned by you, provided that such websites and the use of
            any links to our website comply with these Terms of Use; wherever
            you post a link to our website on any other website, you agree that
            you will do so in an appropriate manner, and not in any way which is
            defamatory or disparaging towards us, which misrepresents us or our
            business, or which causes any harm whatsoever to us or our business;
            and you must not link to our website in order to suggest any form of
            joint venture, partnership, collaboration, affiliation, business
            relationship, approval or endorsement in connection with us where
            none exists and in any event, without having first obtained our
            prior written consent. We may withdraw permission to link to our
            website at any time. In the event that we withdraw permission to
            link to our website and inform you of the same, you must immediately
            remove or cause to be removed any links to our website.
          </p>
          <p>
            EXCLUSIONS AND LIMITATIONS OF LIABILITY We do not exclude our
            liability to you where it would be unlawful to do so, for example,
            for death or personal injury caused by our negligence. If applicable
            law does not allow all or any part of the below limitations of
            liability to apply to you, the limitations will apply to you only to
            the maximum extent permitted by applicable law. If you purchase
            goods or services from our website, different exclusions of
            liability may apply. These are contained in our terms of sale.
            SUBJECT TO THE AFORESAID, IN NO EVENT SHALL WE (INCLUDING OUR
            PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, MEMBERS,
            EMPLOYEES OR AGENTS) UNDER ANY CIRCUMSTANCES WHATSOEVER BE LIABLE TO
            YOU FOR ANY LOSS, DAMAGE (WHETHER DIRECT, INDIRECT, PUNITIVE,
            ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY, OR OTHERWISE)
            COSTS, EXPENSES, LIABILITIES OR PENALTIES, WHETHER IN CONTRACT,
            TORT, BREACH OF STATUTORY DUTY OR OTHERWISE, WHETHER FORESEEABLE OR
            UNKNOWN, ARISING FROM, IN CONNECTION WITH OR RELATING TO: YOUR USE
            OF OUR WEBSITE; ANY CORRUPTION OR LOSS OF DATA; ANY INABILITY TO
            ACCESS OUR WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY
            INTERRUPTIONS, SUSPENSION OR WITHDRAWAL OF OUR WEBSITE (FOR ANY
            REASON WHATSOEVER); ANY USE YOU MAKE OF ANY CONTENT OR MATERIALS ON
            OUR WEBSITE, INCLUDING ANY RELIANCE YOU MAKE ON SUCH CONTENT OR
            MATERIAL; ANY LOSS OF SAVINGS, PROFITS, SALES, BUSINESS OR REVENUE;
            ANY LOSS OF REPUTATION OR GOODWILL; ANY LOSS OF SAVINGS; ANY LOSS OF
            A CHANCE OR OPPORTUNITY; OR ANY OTHER SECONDARY, CONSEQUENTIAL OR
            INDIRECT LOSSES, AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH LOSS OR DAMAGE, WITHOUT LIMITATION, YOU ASSUME AND SHALL BE
            LIABLE FOR THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR
            CORRECTION IN THE EVENT OF ANY SUCH LOSS, DAMAGE, COSTS, EXPENSES,
            LIABILITIES OR PENALTIES ARISING.
          </p>
          <p>
            WE SHALL NOT BE LIABLE FOR ANY DAMAGE THAT YOU COULD HAVE AVOIDED BY
            FOLLOWING OUR ADVICE TO APPLY AN UPDATE OFFERED TO YOU FREE OF
            CHARGE OR FOR DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY
            FOLLOW INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE MINIMUM
            SYSTEM REQUIREMENTS ADVISED BY US. You specifically agree that we
            shall not be liable for any content or the defamatory, offensive or
            illegal conduct of any third party and that the risk of harm or
            damage from the foregoing rests entirely with you. YOU AGREE THAT IN
            THE EVENT THAT YOU INCUR ANY DAMAGES, LOSSES OR INJURIES ARISING OUT
            OF, OR IN CONNECTION WITH, OUR ACTS OR OMISSIONS, THE DAMAGES, IF
            ANY, CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU
            TO AN INJUNCTION PREVENTING ANY EXPLOITATION OF ANY WEBSITE,
            SERVICE, PROPERTY, PRODUCT OR OTHER CONTENT OWNED OR CONTROLLED BY
            US, AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE
            DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION OR
            EXPLOITATION OF ANY WEBSITE, PROPERTY, PRODUCT, SERVICE, OR OTHER
            CONTENT OWNED OR CONTROLLED BY US. To the extent that any of the
            provisions of this clause 14(EXCLUSIONS AND LIMITATIONS OF
            LIABILITY) are unenforceable as outright exclusions of liability,
            they shall be construed as limitations on liability, limiting our
            liability to you to the maximum extent permitted by law.
          </p>
          <p>
            INDEMNIFICATION You (and also any third party for or on behalf of
            whom you operate an account or activity on the website) agree to
            defend (at our request), indemnify and hold us harmless from and
            against any claims, liabilities, damages, losses and expenses,
            including, without limitation, reasonable legal and attorneys’ fees
            and costs, arising out of or in any way connected with any of the
            following (including as a result of your direct activities on the
            website or those conducted on your behalf): your uploads, access to
            or use of the website; your breach or alleged breach of these Terms
            of Use; your violation of any third-party right, including, without
            limitation, any intellectual property right, publicity,
            confidentiality, property or privacy right; your violation of any
            laws, rules, regulations, codes, statutes, ordinances or orders of
            any governmental and quasi-governmental authorities, including,
            without limitation, all regulatory, administrative and legislative
            authorities; or any misrepresentation made by you. You will
            cooperate as fully required by us in the defence of any claim. We
            reserve the right to assume the exclusive defence and control of any
            matter subject to indemnification by you, and you will not, in any
            event, settle any claim without our prior written consent.
          </p>
          <p>
            DISCLAIMERS THE WEBSITE IS PROVIDED ON AN “AS IS”, “AS AVAILABLE”
            AND “WITH ALL FAULTS” BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY
            LAW, WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES OR
            ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS TO: THE
            SERVICE; THE WEBSITE CONTENT; USER CONTENT; OR SECURITY ASSOCIATED
            WITH THE TRANSMISSION OF INFORMATION TO THE WEBSITE. IN ADDITION, WE
            HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET
            ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS.
          </p>
          <p>
            WE DO NOT REPRESENT OR WARRANT THAT THE SERVICE WILL BE ERROR-FREE
            OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, OR THAT THE
            SERVICE OR THE SERVER THAT MAKES THE SERVICE AVAILABLE IS FREE FROM
            ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES. WE
            DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION
            (INCLUDING ANY INSTRUCTIONS) ON THE SERVICE IS ACCURATE, COMPLETE OR
            USEFUL. YOU ACKNOWLEDGE THAT YOUR USE OF THE WEBSITE IS AT YOUR SOLE
            RISK. WE DO NOT WARRANT THAT YOUR USE OF THE WEBSITE IS LAWFUL IN
            ANY PARTICULAR JURISDICTION, AND WE SPECIFICALLY DISCLAIM SUCH
            WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER
            OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY
            TO YOU TO THE EXTENT SUCH JURISDICTION'S LAW IS APPLICABLE TO YOU
            AND THESE TERMS OF USE. BY ACCESSING OR USING THE WEBSITE YOU
            REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY
            JURISDICTION WHERE YOU ACCESS OR USE THE SERVICE. WE DO NOT ENDORSE
            CONTENT AND SPECIFICALLY DISCLAIM ANY RESPONSIBILITY OR LIABILITY TO
            ANY PERSON OR ENTITY FOR ANY LOSS, DAMAGE (WHETHER ACTUAL,
            CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR
            OTHER CAUSE OF ACTION OF ANY KIND OR CHARACTER BASED UPON OR
            RESULTING FROM ANY CONTENT.
          </p>
          <p>AGE RESTRICTIONS ON USE OF OUR WEBSITE</p>
          <p>
            Our website and any products or services available on or via the
            website are not intended for use by individuals under the age of 18.
            <br />
            IF YOU ARE UNDER THE AGE OF 18, YOU MUST NOT USE OUR WEBSITE,
            PURCHASE OR ATTEMPT TO PURCHASE ANY OF OUR PRODUCTS OR SERVICES, OR
            SUBMIT ANY INFORMATION ABOUT YOU OR ANYONE ELSE TO US.
            <br />
            We do not knowingly or intentionally process information about any
            individual under the age of 18.
          </p>
          <p>
            Governing law and jurisdiction
            <br />
            These Terms of Use, any documents they refer to, and any disputes
            arising from or in relation to them or any documents they refer to,
            whether contractual or non-contractual, shall be governed by and
            construed in accordance with English law.
            <br />
            The courts of England and Wales shall have exclusive jurisdiction
            over any claims or disputes arising from or in relation to these
            Terms of Use and any documents they refer to.
          </p>
          <p>
            Copyright
            <br />
            The copyright in these Terms of Use is either owned by, or licensed
            to, us and is protected by copyright laws around the world and
            copyright protection software. Unless expressly indicated otherwise,
            all intellectual property rights in this document and elsewhere on
            our website, including any content on our website, are reserved.
          </p>
        </div>
      </div>
      <div className="crop-svg">
        <div className="cropSvg">
          <FontAwesomeIcon icon={faComments} />
          <p class="icon-bar__text ">chat</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faEnvelope} />
          <p class="icon-bar__text ">email</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faPhone} />
          <p class="icon-bar__text ">call</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faGear} />
          <p class="icon-bar__text ">FAQ</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
