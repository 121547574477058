import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo">
            <div className="icon-com">
              <img alt="" src="/head-foot/head.png" />
              <img alt="" src="/head-foot/image.png" className="twi-face" />
              <p>The smarter way to clean, E-Cloth is enough.</p>
              <img alt="" src="/head-foot/foot2.jpg" className="choushu" />

              <div className="legalLeft">
                © 2024 E-Cloth Ltd. | Privacy Policy | Terms Of Use | Sitemap{" "}
              </div>
            </div>
            <ul>
              <h3 className="comImfoH3">Site Links</h3>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About E-Cloth</Link>
              </li>
              <li>
                <Link to="/terms">Terms Of Use</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/particular">特定商取引法に基づく表記</Link>
              </li>
            </ul>
          </div>

          <div className="comImfo12">
            <div className="com-img">
              <img alt="" src="/head-foot/foot.png" />
              <p>Find E-Cloth at a retail store near you.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
